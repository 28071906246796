import configuredAxios from 'helpers/configuredAxios'
import { getServerLanguageCode } from 'helpers/country'

const fetchSystemMaintainService = (defaultLang) => {
  const axios = configuredAxios('config')
  const lang = getServerLanguageCode(defaultLang)
  return axios.get(`/search/website-outage?language_code=${lang}`).then((result) => {
    return result.data
  })
}

export { fetchSystemMaintainService }
